import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import classNames from 'classnames'

import { useUserSession } from 'hooks'
import { useMutation } from 'hooks/useMutation'
import Button from 'components/Button'
import ButtonLink from 'components/ButtonLink'
import { Container } from 'modules/marketplace/layout/components/ResponsiveContainers'

import GET_REP_WELCOME_BANNER_DATA from './graphql/GetRepWelcomeBannerData.graphql'
import { GetRepWelcomeBannerData } from './graphql/__generated__/GetRepWelcomeBannerData'
import COMPLETE_REP_HELP_GUIDE from './graphql/CompleteRepHelpGuideMutation.graphql'
import {
  CompleteRepHelpGuideMutation,
  CompleteRepHelpGuideMutationVariables
} from './graphql/__generated__/CompleteRepHelpGuideMutation'
import { RepKindEnum } from '../../../../../__generated__/globalTypes'

import styles from './WelcomeRepBanner.module.css'

const cx = classNames.bind(styles)

const WelcomeRepBanner = () => {
  const router = useRouter()
  const { isRep, currentUser: user, currentAccount } = useUserSession()
  const [welcomeBannerDismissed, setWelcomeBannerDismissed] = useState(true)
  const [isIndependent, setIsIndependent] = useState(false)

  const [getRepWelcomeBannerData] = useLazyQuery<GetRepWelcomeBannerData>(GET_REP_WELCOME_BANNER_DATA)
  const [completeRepHelpGuide] = useMutation<CompleteRepHelpGuideMutation, CompleteRepHelpGuideMutationVariables>(
    COMPLETE_REP_HELP_GUIDE,
    {
      onCompleted: data => {
        if (data?.updateRep?.success && data.updateRep.rep?.welcomeBannerDismissed) {
          setWelcomeBannerDismissed(true)
        }
      }
    }
  )

  const dismissBanner = async () => {
    if (currentAccount?.rep?.id) {
      setWelcomeBannerDismissed(true)

      await completeRepHelpGuide({
        variables: {
          input: {
            id: `${currentAccount.rep.id}`,
            attributes: {
              welcomeBannerDismissed: true
            }
          }
        }
      })
    }
  }

  useEffect(() => {
    const loadData = async () => {
      if (!router.isReady) return

      if (isRep) {
        const { data } = await getRepWelcomeBannerData()
        const independent = data?.currentRep?.kind === RepKindEnum.INDEPENDENT
        setWelcomeBannerDismissed(data?.currentRep?.welcomeBannerDismissed ?? false)
        setIsIndependent(independent)
      }
    }

    loadData()
  }, [router.isReady, getRepWelcomeBannerData, isRep])

  if (welcomeBannerDismissed || user == null) return null

  const firstName = `${user.firstName}`

  return (
    <div className={styles.bannerContainer}>
      <Container>
        <div className={styles.bannerInnerContainer}>
          <div className={styles.mainMessageContainer}>
            <div className={styles.bannerImageContainer}>
              <img
                src="/marketing/rep-page/connect-illustration.svg"
                alt="Welcome Banner"
                className={styles.bannerImage}
              />
            </div>
            <div className={cx(styles.featureContent, styles.promotionFeatureContentText)}>
              <h2 className={styles.mainMessageHeader}>Welcome to ����vlog����, {firstName}!</h2>
              <p className={styles.mainMessage}>
                {isIndependent && `You can now browse the ����vlog���� marketplace and request to sell for new Sellers. `}
                Don’t forget to download{' '}
                <a target="_blank" rel="noreferrer" href="https://itunes.apple.com/app/id582233251">
                  ����vlog���� for iPad
                </a>{' '}
                from the App Store to take orders from your Buyers!
              </p>
              <div className={styles.linksContainer}>
                <ButtonLink
                  target="_blank"
                  className={cx(styles.externalLink, styles.transparentLink)}
                  kind="link"
                  size="default"
                  href={`https://support.fieldfolio.com/en/collections/3472127-sales-reps`}
                  icon="external-link"
                  iconPosition="right"
                  label="Learn more about using ����vlog���� for iPad"
                />
              </div>
            </div>
          </div>
          <div className={styles.dismissContainer}>
            <Button
              kind="link"
              size="default"
              icon="x"
              iconPosition="right"
              className={cx(styles.dismissButton, styles.transparentLink)}
              onClick={dismissBanner}>
              <span className={styles.hideForMobile}>Dismiss</span>
            </Button>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default WelcomeRepBanner
